.SignInPageCaptcha {
  height: 0;
  width: 0;
}

.SignInPageTermsOfUse {
  margin-top: 1rem;
}

.FormWrapperStaySignedIn {
  margin: -0.75rem 0 0.75rem;
}
